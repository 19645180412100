// import Alert from 'bootstrap/js/dist/alert.js'
// import Button from 'bootstrap/js/dist/button.js'
// import Carousel from 'bootstrap/js/dist/carousel.js'
// import Collapse from 'bootstrap/js/dist/collapse.js'
// import Dropdown from 'bootstrap/js/dist/dropdown.js'
// import Modal from 'bootstrap/js/dist/modal.js'
// import Offcanvas from 'bootstrap/js/dist/offcanvas.js'
// import Popover from 'bootstrap/js/dist/popover.js'
// import ScrollSpy from 'bootstrap/js/dist/scrollspy.js'
// import Tab from 'bootstrap/js/dist/tab.js'
// import Toast from 'bootstrap/js/dist/toast.js'
// import Tooltip from 'bootstrap/js/dist/tooltip.js'

// export default {
//   Alert,
//   Button,
//   Carousel,
//   Collapse,
//   Dropdown,
//   Modal,
//   Offcanvas,
//   Popover,
//   ScrollSpy,
//   Tab,
//   Toast,
//   Tooltip
// }
